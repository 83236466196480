import request from './request'

const Review = {

  setReviewState(data) { 
    return request({
      url: `/v1/review`,
      method: "post",
      data
    });
  },
  getReview() { 
    return request({
      url: `/v1/review`,
      method: "get"
    });
  }
};

export default Review