<template>
  <el-dialog
    title="Please rate your expercience with us"
    :visible.sync="dialogFormVisible"
    :width="isMobile ? '90%' : '450px'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    
  >
    <el-form :model="formData">
      <el-form-item>
        <div class="text-center">
          <el-rate v-model="formData.customer_feedback_point"> </el-rate>
        </div>
      </el-form-item>

      <el-form-item v-if="formData.customer_feedback_point">
        <p  v-if="[1, 2].includes(formData.customer_feedback_point)" class="lh-sm text-sm mt-3 text-center">
          We are sorry to hear that you
          <span class="text-red">{{
            texts[formData.customer_feedback_point - 1]
          }}</span>
          this time<br />Please help us to improve the products and services
        </p>
        <el-input
          type="textarea"
          v-model="formData.customer_comment"
          placeholder="write a review"
          v-input-filter-en
        ></el-input>
      </el-form-item>
    </el-form>
    <span v-if="formData.customer_feedback_point > 0" slot="footer" class="dialog-footer text-center">
      <el-button
      
        type="primary"
        @click="onSubmit"
        v-loading="submiting"
        :disabled="submiting"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import ReviewApi from "@/api/review";
import { isMobile } from "@/utils/helpers";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
    reviewState() {
      return this.$store.getters.reviewstate;
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      submiting: false,
      texts: [
        "Very Dissatisfied",
        "Dissatisfied",
        "Neutral",
        "Satisfied",
        "Excellent",
      ],
      formData: {
        customer_feedback_point: 0,
        customer_comment: "",
        paymentuid: "",
        from_currency: "",
        to_currency: "",
        volume: 0,
        price: 0,
        review_from: "",
      },
    };
  },

  methods: {
    show(review_from, data) {
      if (this.reviewState) return;

      this.setData(review_from, data);
      this.dialogFormVisible = true;
      
    },

    setData(review_from, data) {
      this.formData.paymentuid = data.payment_UID;
      this.formData.from_currency = data.currency_from;
      this.formData.to_currency = data.currency_to;
      this.formData.volume = review_from === 'payment_domestic' ? parseFloat(data.amount) :  parseFloat(data.receive);
      this.formData.price = parseFloat(data.rate);
      this.formData.review_from = review_from;
    },
    onSubmit() {
      this.submiting = true;
      ReviewApi.setReviewState(this.formData).then(({ result, message }) => {
        this.submiting = false;
        if (result) {
          this.$store.dispatch("user/setReviewState", true).then(() => {
            this.dialogFormVisible = false;
            this.$swal(`Message`, message, "success").then(()=>{
                this.onClose()
              });
          });
        }
      });
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped></style>
