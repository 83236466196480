import request from './request'
import {uuid} from 'vue-uuid'

const Device = {
  getDeviceList(){
    return request({
      url: "/v1/devicelist",
      method: "get"
    });
  },
  removeDevice(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/device/remove",
      method: "delete",
      data: data
    });
  },

}

export default Device