<template>
  <div>
    <div class="text-center p-2">
      <el-button
        v-if="!quoted"
        type="warning"
        round
        :loading="quotedLoading"
        @click="rateCalc()"
        >Quote</el-button
      >
      <el-progress
        v-if="quoted"
        type="circle"
        :percentage="count"
        :format="format"
        :width="50"
        :stroke-width="5"
        :color="colors"
        v-loading="quotedLoading"
      ></el-progress>
    </div>
    <div
      v-if="!fx && feecontrol == 'x2'"
      class="card p-2 mt-2 mb-3 alert-danger"
    >
      <div class="text-center">
        <span>You will get charge double prices if your ID is expired</span>
      </div>
    </div>
    <div
      class="card p-2 pt-2 mt-2 mb-3"
      v-if="!fx && quoted && fasttrackLabel.length > 0"
    >
      <el-form-item label="Fast Track" v-if="canfasttrack">
        <el-switch
          v-model="fasttrack"
          @change="rateCalc()"
          style="margin-right: 10px"
        ></el-switch>
        Payment to account in Thailand in 5-10 minute
      </el-form-item>
      <label
        v-for="(item, index) of fasttrackLabel"
        :key="index"
        class="el-form-item__label text-left"
        :style="`line-height:20px;color:${item.color};`"
      >
        {{ item.content }}
      </label>
    </div>
    <div class="card p-2 mt-2 mb-3">
      <div class="mt-2">
        <div class="d-flex justify-content-between align-items-center">
          <b>Rate</b>
          <span v-if="calcData.rate">{{
            `1 ${this.from} = ${calcData.rate} ${this.to}`
          }}</span>
        </div>
        <div
          v-if="ratePlus"
          class="d-flex justify-content-between align-items-center text-red"
        >
          <b>Rate Plus</b>
          <span style="line-height: 16px" class="text-right"
            >{{ `1 ${this.from} = ` }}
            <span style="text-decoration: line-through">{{
              calcData.rate
            }}</span>
            {{ `${ratePlus} ${this.to}` }}<br />
            <span class="text-xs"
              >when tranfer over than 3000 GBP or EUR</span
            ></span
          >
        </div>
        <div
          v-if="!fx"
          class="d-flex justify-content-between align-items-center"
        >
          <b>Fee</b> <span  class="text-right">{{ calcData.fee }}
          <br>
          <span class="text-right text-red" v-if="messageFree">{{ messageFree }}</span>
          </span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b>Receive</b> <span>{{ calcData.receive }}</span>
        </div>
        <div
          v-if="!fx"
          class="d-flex justify-content-between align-items-center"
        >
          <b>Total amount</b> <span>{{ calcData.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Utils from "@/utils";
export default {
  props: {
    from: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    amount: {
      type: String,
      default: "",
    },

    fx: {
      type: Boolean,
      default: false,
    },
    domestic: {
      type: Boolean,
      default: false,
    },
    customertype: {
      type: String,
      default: "wallet",
    },
    paymenttype: {
      type: String,
      default: "sell",
    },
    freeuid: {
      type: String,
      default: "",
    },
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    feecontrol() {
      return this.$store.getters.allowedtrade;
    },
  },
  data() {
    return {
      quoted: false,
      quotedLoading: false,
      count: 100,
      timeout: 30,
      time: this.timeout,
      isPaused: false,
      timer: null,
      colors: [
        { color: "#E6A23C", percentage: 100 },
        { color: "#f5365c", percentage: 40 },
      ],
      calcData: {
        rate: "",
        fee: "",
        receive: "",
        total: "",
        freeuid: ""
      },
      fasttrack: false,
      fasttrackLabel: [],
      canfasttrack: false,
      ratePlus: "",
      messageFree: ""
    };
  },
  methods: {
    format(percentage) {
      return this.time;
    },

    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.time = this.timeout;
      this.quoted = true;
      this.timer = setInterval(() => {
        if (!this.isPaused) {
          this.time--;
          this.count = (this.time / this.timeout) * 100;
          if (this.time == 1) {
            this.clearTimer();
          }
        }
      }, 1000);
    },
    clearTimer() {
      this.quoted = false;
      this.count = 100;
      this.time = this.timeout;
      this.fasttrack = false;
      this.fasttrackLabel = [];
      this.canfasttrack = false;
      this.isPaused = false;
      this.ratePlus = "";
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.$emit("onTimeout");
    },
    rateCalc() {
      if (!this.from) {
        this.$swal("Message", "Please select from account", "error");
        return false;
      }

      if (!this.to) {
        this.$swal("Message", "Please select to account", "error");
        return false;
      }

      if (!this.amount) {
        this.$swal("Message", "Please enter Amount", "error");
        return false;
      }

      this.quotedLoading = true;
      const host =
        process.env.NODE_ENV === "development"
          ? "https://services-stage.ab-fx.com"
          : "https://services.ab-fx.com";
      // const url = host + "/order/api/app/calculator.php";
      const url = host + "/order/api/app/calculatorlive.php";
      const formCalc = {
        uid: Utils.uuid(),
        lang: "en",
        device: "browser",
        apiversion: 0.1,
        submit: {
          customertype: this.customertype,
          customeruid: this.plusuid,
          from: this.from,
          to: this.to,
          pay:
            this.amount && this.paymenttype == "sell"
              ? parseFloat(this.amount)
              : 0,
          destinationreceive:
            this.amount && this.paymenttype == "buy"
              ? parseFloat(this.amount)
              : 0,
          cardtype: "bank",
          feetype: this.fasttrack ? "fasttrack" : "normal",
          fx: this.fx,
          domestic: this.domestic,
          feecontrol: this.feecontrol,
          freeuid: this.freeuid,
        },
      };
      this.messageFree = "";
      const option = {
        method: "POST",
        data: JSON.stringify(formCalc),
        url,
      };

      axios(option)
        .then(({ data }) => {
          this.quotedLoading = false;
          if (data) {
            this.startTimer();
            let feeIndex = this.fasttrack ? 1 : 0;
            let rate = parseFloat(data["response"]["rate"]).toFixed(4);
            let fee = parseFloat(data["feelist"][feeIndex]["feevalue"]);
            let total = parseFloat(data["totalpayment"]["value"]);
            let receive = parseFloat(data["destinationreceive"]["value"]);

            this.calcData.rate = `${rate}`;
            this.calcData.fee = `${fee} ${this.from}`;
            this.calcData.receive = `${receive} ${this.to}`;
            this.calcData.total = `${total} ${this.from}`;

            if (data["feelist"][1].textarray) {
              this.fasttrackLabel = data["feelist"][1].textarray.en;
              this.canfasttrack =
                parseFloat(data["feelist"][1]["feevalue"]) > 0 ? true : false;
            }

            if (
              this.customertype == "normal" &&
              ["GBP", "EUR"].includes(this.from) &&
              ["THB"].includes(this.to) &&
              parseFloat(this.amount) < data["rateplusstartfrom"]
            ) {
              this.ratePlus = `${data["display-rate-plus"]}`;
            }

            this.messageFree = data["message"] || "";

            let result = {
              verifytoken: data["verifytoken"],
              qoutetoken: data["qoutetoken"],
              response: data["response"],
              fasttrack: this.fasttrack,
            };

            this.$emit("onQuoted", result);
          }
        })
        .catch(() => {
          this.quotedLoading = false;
          this.$swal("Message", "Quote Api error. Please try again.", "error");
        });
    },
  },
};
</script>

<style lang="scss">
.el-progress__text {
  font-size: 20px !important;
}
</style>
