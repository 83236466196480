<template>
  <div>
    <el-dialog
      title="Security setting"
      :visible.sync="dialogAddDevice"
      width="60%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div>
        <img
          style="width: 50%"
          class="img-center"
          src="/img/modal/psd2-2factor.jpg"
          alt=""
        />
        <p class="text-md text-center">
          For more secure to your online banking account, Just simply download
          our mobile app ABFX <br />and linking with an account to active 2
          Factor verification.
        </p>
        <div class="text-center">
          <el-button type="primary" @click="$router.push('/device')">OK</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { isMobile } from "@/utils/helpers";
import deviceApi from "@/api/device";
export default {
    computed: {
      isMobile() {
        return isMobile();
      },
      username() {
        return this.$store.getters.username;
      },
      accessRole() {
        return this.$store.getters.accessrole;
      },
    },
    data() {
      return {
        loading: false,
        dialogAddDevice: false
      };
    },
    methods:{
      getDeviceList() {
        if(!this.accessRole.devicemanage){
          return false;
        }
        this.loading = true;
        deviceApi.getDeviceList().then(({ result, data }) => {
          this.loading = false;
          if (result) {
            if (data.length === 0 && !this.isMobile && this.username.indexOf("stage") === -1) {
              this.dialogAddDevice = true;
            }
          }
        });
      },
    },
    mounted() {
      this.getDeviceList();
    }
};
</script>

<style>

</style>