<template>
  <el-select v-model="purpose" @change="handleChange" style="width: 100%">
    <el-option
      v-for="item in options"
      :key="item"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "select-purpose",
  props: {
    value: String
  },
  data: () => ({
    purpose: "",
    options: ["Support family or family expenses","Purchase of accommodation or property", "Investment or bill payment", "Personal saving","Personal expenses", "Pay off debt","Other"]
  }),
  methods: {
    handleChange(value) {
      this.purpose = value;
      this.$emit("input", this.purpose);
    },
  },
  created() {
    if (this.value) {
      this.purpose = this.value;
    }
  },
};
</script>

<style>
</style>