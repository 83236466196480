<template>
  <div class="container-fluid mt--6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 text-center">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/payment' }"
                >Payments and Transfers</el-breadcrumb-item
              >
              <el-breadcrumb-item>Make a Payment THB</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9 col-md-12" v-if="!paymented">
        <div class="card mb-3">
          <div class="card-header">
            <h3 class="mb-0">THB Payment (Thailand)</h3>
          </div>

          <div v-if="allowedTrade == 'no'" class="card-body">
            <div class="mb-4">
              <div class="card p-2 mt-2 mb-3 alert-danger">
                <div class="text-center">
                  <span>Your account can't use this function</span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card-body"
            v-loading="loading"
            v-if="allowedTrade != 'no'"
          >
            <div class="mb-4">
              <CompareCurrency
                ref="comparecurrency"
                :swapbutton="false"
                theme="light"
                type="plus"
                :currencies="curencies"
              />
            </div>
            <div class="row justify-content-md-center">
              <div class="col-lg-8 col-md-12">
                <el-form
                  ref="formPaymentTHB"
                  :model="formPayment"
                  :rules="formPaymentRule"
                  :label-position="labelPosition"
                  size="small"
                  label-width="190px"
                >
                  <el-form-item
                    label="From Bank Account"
                    prop="from_bankholderid"
                  >
                    <el-select
                      v-model="formPayment.from_bankholderid"
                      @change="selectCurrency(formPayment.from_bankholderid)"
                      class="form-control-alternative"
                      filterable
                    >
                      <el-option
                        v-for="item of bankAccount"
                        :key="item.uid"
                        :label="`${item.currency} (${item.cardholderid})`"
                        :value="item.cardholderid"
                      ></el-option>
                    </el-select>
                    <div
                      class="text-xs pl-3 mt-1 lh-180"
                      v-loading="loadingBalance"
                    >
                      {{ balance }}
                      <div v-html="detailBankAccount"></div>
                    </div>
                  </el-form-item>

                  <el-form-item label="To"> THB </el-form-item>

                  <el-form-item label="Payee" prop="payee_uid">
                    <el-select
                      v-model="formPayment.payee_uid"
                      @change="selectPayee"
                      placeholder="Select Payee"
                      class="form-control-alternative"
                      filterable
                    >
                      <el-option
                        v-for="payee of payeeList"
                        :key="payee.uid"
                        :label="`${payee.name} (acc:${payee.accountnumber} ref:${payee.reference})`"
                        :value="payee.uid"
                      ></el-option>
                    </el-select>
                    <el-button
                      v-if="isMobile"
                      type="primary"
                      @click="
                        $router.push('/payee/add/thb/local-thb?from=payment')
                      "
                      >Add</el-button
                    >
                  </el-form-item>

                  <el-form-item label="Payee Name">
                    <el-input
                      v-model="payeeInfo.name"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Account Number">
                    <el-input
                      v-model="payeeInfo.accountnumber"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Bank Name">
                    <el-input
                      v-model="payeeInfo.bankname"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Branch">
                    <el-input
                      v-model="payeeInfo.branch"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Province">
                    <el-input
                      v-model="payeeInfo.province"
                      readonly
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="You want to Sell or Buy?">
                    <el-radio-group
                      v-model="paymentType"
                      @change="amountEnter()"
                    >
                      <el-radio label="sell" border size="medium">Sell</el-radio>
                      <el-radio label="buy" border size="medium">Buy</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item
                    :label="`Amount ${
                      paymentType == 'sell'
                        ? wallet_from_currency
                        : wallet_to_currency
                    }`"
                    prop="amount"
                  >
                    <el-input
                      type="number"
                      v-model="formPayment.amount"
                      @input="amountEnter()"
                      min="0"
                      placeholder="Amount"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>
                  <free-trade-list @onChanged="onSelectFreeTrade"></free-trade-list>
                  <div>
                    <QuoteButton
                      ref="quotebtn"
                      :customertype="isBusiness ? 'corp' : 'plus'"
                      :from="wallet_from_currency"
                      :to="wallet_to_currency"
                      :amount="formPayment.amount"
                      :domestic="true"
                      :fx="false"
                      :paymenttype="paymentType"
                      :freeuid = "freeuid"
                      @onQuoted="onQuoted"
                      @onTimeout="onTimeout"
                    />
                  </div>

                  <el-form-item label="Purpose of payment" prop="purpose">
                    <select-purpose
                      v-model="formPayment.purpose"
                      class="form-control-alternative"
                    ></select-purpose>
                  </el-form-item>

                  <el-form-item
                    label="Other Purpose"
                    prop="purposeOther"
                    v-if="formPayment.purpose === 'Other'"
                  >
                    <el-input
                      v-model="formPayment.purposeOther"
                      v-input-filter
                      placeholder="Other Purpose"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Reference" prop="reference">
                    <el-input
                      v-model="formPayment.reference"
                      v-input-filter
                      placeholder="Reference"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row justify-content-xl-center">
              <div class="col-xl-12 text-center">
                <el-button @click="backView()">Cancel</el-button>
                <el-button
                  v-if="quoted"
                  type="primary"
                  @click="onSubmit()"
                  v-loading="submiting"
                  :disabled="submiting"
                  >Submit</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <payment-receipt :data="paymentReceipt" v-if="paymented" />

      <div class="col-xl-3 col-md-12" v-if="!isMobile">
        <div class="card mb-3">
          <div class="card-body">
            <a
              href="javascript:void(0)"
              @click="$router.push('/payee/add/thb/local-thb?from=payment')"
              class="btn btn-primary btn-block"
              >Add Payee</a
            >
          </div>
        </div>
      </div>
    </div>
    <PinDialog ref="pincodedialog" @checked="pincodeChecked" />
    <DeviceSettingDialog />
    <ReviewRateDialog ref="reviewratedialog"/>
  </div>
</template>

<script>
import Utils from "@/utils";
import BankApi from "@/api/bankaccount";
import PaymentApi from "@/api/payment";
import PaymentReceipt from "./compoments/PaymentReceipt.vue";
import { isMobile } from "@/utils/helpers";
// import axios from "axios";
import PinDialog from "@/components/PinCode/PinDialog";
import DeviceSettingDialog from "@/views/Device/SettingDialog.vue";
import SelectPurpose from "@/components/Input/SelectPurpose";
import QuoteButton from "@/views/Wallet/components/QuoteButton.vue";
import CompareCurrency from "@/components/Chart/CompareCurrency";
import FreeTradeList from '@/components/FreeTrade/FreeTradeList.vue';
import ReviewRateDialog from '@/components/Review/Review'
export default {
  components: {
    PaymentReceipt,
    PinDialog,
    DeviceSettingDialog,
    SelectPurpose,
    QuoteButton,
    CompareCurrency,
    FreeTradeList,
    ReviewRateDialog
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
    isBusiness() {
      return this.$store.getters.business;
    },
    allowedTrade() {
      return this.$store.getters.allowedtrade;
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      loadingBalance: false,
      balance: "",
      paymented: false,
      isPincode: false,
      quoted: false,
      enterTimeout: null,
      payeeList: [],
      wallet_from_currency: "",
      wallet_to_currency: "",
      paymentType: "sell",
      freeuid:"",
      payeeInfo: {
        name: "-",
        accountnumber: "-",
        bankname: "-",
        branch: "-",
        province: "-",
      },
      formPayment: {
        from_bankholderid: "",
        currency_from: "",
        payee_uid: "",
        amount: "",
        fee: 0,
        rate: 0,
        reference: "",
        exchangerate_type: "normal",
        use_fasttrack: false,
        purpose: "",
        purposeOther: "",
        qoutetoken: "",
        verifytoken: "",
        calresponse: {},
      },

      formPaymentRule: {
        from_bankholderid: [
          {
            required: true,
            message: "Please select Bank Account",
            trigger: "blur",
          },
        ],
        payee_uid: [
          { required: true, message: "Please select Payee", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "Please enter Amount", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message: "Please enter reference",
            trigger: "blur",
          },
          {
            message: " Reference must put 6-18 character ",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
        purpose: [
          { required: true, message: "Please enter purpose", trigger: "blur" },
        ],
        purposeOther: [
          {
            required: true,
            message: "Please enter other purpose",
            trigger: "blur",
          },
        ],
      },
      paymentReceipt: {},
      bankAccount: [],
      detailBankAccount: "",
      curencies: [],
    };
  },
  methods: {
    async getBankAccount() {
      this.balance = "";
      this.loading = true;
      this.bankAccount = await Utils.getBankAccount();
      this.loading = false;
      this.getBankCurrency();
    },
    getBankCurrency() {
      this.bankAccount.forEach((item) => {
        if (!this.curencies.includes(item.currency)) {
          this.curencies.push(item.currency);
        }
      });
    },
    getPayee() {
      this.loading = true;
      this.payeeList = [];
      PaymentApi.getPayeeByCurrency("thb", "local-thb").then(
        ({ result, data }) => {
          this.loading = false;
          if (result) {
            this.payeeList = Utils.payeeFilter(data);
            this.getQueryPayeeUID();
          }
        }
      );
    },
    getBalance(bankholderid) {
      this.balance = "";
      this.loadingBalance = true;
      BankApi.getAccountBalance(bankholderid).then(async ({ result, data }) => {
        this.loadingBalance = false;
        if (result) {
          this.balance = `Balance : ${data.balance}`;
          await this.getBankAccountDetail(bankholderid);
        }
      });
    },
    selectPayee(uid) {
      const payee = Utils.findObject(this.payeeList, uid, "uid");
      this.payeeInfo.name = payee.name;
      this.payeeInfo.accountnumber = payee.accountnumber;
      this.payeeInfo.bankname = payee.bankname;
      this.payeeInfo.branch = payee.bankaddress;
      this.payeeInfo.province = payee.bankcityState;
      this.formPayment.reference = payee.reference;
      this.wallet_to_currency = "THB";
      this.amountEnter();
    },
    getQueryPayeeUID() {
      if (this.$route.query && this.$route.query.payee) {
        this.formPayment.payee_uid = this.$route.query.payee;
        this.selectPayee(this.formPayment.payee_uid);
      }
    },
    selectCurrency(value) {
      const result = Utils.findObject(this.bankAccount, value, "cardholderid");
      this.formPayment.currency_from = result.currency;
      this.wallet_from_currency = result.currency;
      this.$refs.comparecurrency.changeFrom(this.wallet_from_currency);

      this.amountEnter();
      this.getBalance(value);
    },
    pincodeChecked(pincode) {
      this.isPincode = pincode;
      if (!pincode) {
        this.submiting = false;
      } else {
        this.onSubmit();
      }
    },
    onSubmit() {
      this.$refs.formPaymentTHB.validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.$refs.quotebtn.isPaused = true;
          if (!this.isPincode && this.isMobile) {
            this.$refs.pincodedialog.show("payment");
            return false;
          }
          let model = JSON.parse(JSON.stringify(this.formPayment));
          model.amount = parseFloat(this.formPayment.amount);

          PaymentApi.paymentCrossCurrencyV2(model).then(
            ({ result, data, message }) => {
              this.amountEnter();
              this.submiting = false;
              if (result) {
                if (data.scapending) {
                  this.socketSubscription();
                } else {
                  this.paymentReceipt = data.receipt;
                  this.$swal(
                    `Message`,
                    `Make a Payment THB is success`,
                    "success"
                  ).then(() => {
                    this.$emit("updateBalance");
                    this.paymented = true;
                    this.showReview();
                  });
                }
              } else {
                this.$refs.quotebtn.isPaused = false;
                this.$swal("Message", message, "error");
              }
            }
          );
        }
      });
    },
    amountEnter() {
      this.$refs.quotebtn.clearTimer();
    },
    onQuoted(data) {
      this.formPayment.verifytoken = data["verifytoken"];
      this.formPayment.qoutetoken = data["qoutetoken"];
      this.formPayment.calresponse = data["response"];
      this.formPayment.rate = parseFloat(this.formPayment.calresponse.rate);
      this.formPayment.fee = parseFloat(this.formPayment.calresponse.fee);
      this.formPayment.use_fasttrack = data.fasttrack;
      this.quoted = true;
      this.$refs.comparecurrency.getRate();
    },
    onSelectFreeTrade(freeuid){
      this.amountEnter();
      this.freeuid = freeuid;
    },
    onTimeout() {
      this.quoted = false;
    },

    backView() {
      this.$router.push(`/payment`);
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Make Payment Pending",
        "Please confirm make payment from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("payment:" + this.plusuid, (response) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (response.result) {
          this.$swal.fire({
            icon: "success",
            title: "Make a Payment THB is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.paymentReceipt = response.data.receipt;
            this.$emit("updateBalance");
            this.paymented = true;
            this.showReview();
          }, 2000);
        } else {
          this.$swal("Message", response.message, "error");
        }
      });
    },
    async getBankAccountDetail(bankholderid) {
      this.detailBankAccount = await Utils.getBankAccountDetail(
        bankholderid,
        this.bankAccount,
        false
      );
    },
    showReview(){
      this.$refs.reviewratedialog.show('payment_international', this.paymentReceipt)
    }
  },
  async created() {
    await this.getBankAccount();
    this.getPayee();
    this.$refs.comparecurrency.changeTo("THB");
  },
};
</script>

<style></style>
