<template>
  <div class="col-xl-9 col-md-12">
    <div class="card mb-3">
      <div class="card-header position-relative">
        <h3 class="mb-0">Payment Confirmed</h3>
        <div class="position-absolute top-2 right-2 pt-1 pr-1" v-if="Object.keys(data).length > 0">
          <a href="javascript:void(0)" class="btn btn-success" @click="downloadRecive()">Download Receive</a>
        </div>
      </div>
      <div class="card-body text-sm" v-if="Object.keys(data).length > 0">
        <div class="row justify-content-xl-center">
          <div class="col-lg-12 col-xl-8">
            <div class="text-center text-xs">
              We received your payment request at {{ data.payment_time }}
            </div>
            
            <div class="mb-4"></div>
            <div class="font-weight-700 text-center mb-2">From</div>
            <div class="text-center">
              <div class="text-lg pr-2">{{ data.sender_name }}</div>
              <span class="font-weight-700">({{ data.sender_accountnumberiban }}, {{ data.sender_sortcodeswift}})</span>
            </div>
            <div class="mb-4"></div>
            <div class=" font-weight-700 text-center mb-2">To</div>
            <div class="text-center">
              <div class="text-lg pr-2">{{ data.payee_name }}</div>
              <span class="font-weight-700">({{ data.payee_accountnumberiban }}, {{ data.payee_sortcodeswift }})</span>
              <div><span class="font-weight-700">Reference</span><span> {{ data.reference }}</span></div>
              <div><span class="font-weight-700">PaymentID</span><span> {{ data.paymentID }}</span></div>
            </div>
            <div class="mb-4"></div>
            <div class="text-center">
              <div class="font-weight-700">Payment amount</div>
              <span class="text-lg">{{ data.currency_from }} {{ data.amount }}</span>
            </div>
            <div class="mb-4"></div>
            <div class=" text-xs">
              <div class="font-weight-700">Payment date</div>
              <div v-if="data.currency_to.toLowerCase() === 'thb'">
                <div class="font-weight-400">We've successfully processed your payment to {{ data.payee_name }} for
                  {{ data.currency_to }} {{ data.receive }}. The fund shall credit within 5-10 minutes to beneficent bank account in Thailand if it payment process within business hours.<br />
                  Office closed or Bank Holiday and the payment process may take longer.
                </div>
              </div>
              <div v-if="data.currency_to.toLowerCase() !== 'thb' && data.product==='swift'">
                <div class="font-weight-400">ASAP - Swift payment will submit to bank processing under bank time.</div>
                <div class="font-weight-400">We've successfully processed your payment to {{ data.payee_name }} for
                  {{ data.currency_to }} {{ data.receive }}. The money will usually reach the recipient's account within 1-3 working day.<br />
                  Please note: The bank cut off time and destination time zone case the payment time processing via swift transaction.
                </div>
              </div>
              <div v-if="data.currency_to.toLowerCase() !== 'thb' && data.product!=='swift'">
                <div class="font-weight-400">ASAP This payment will usually reach the recipient's account {{checkCHAPS()}}</div>
                <div class="font-weight-400">>We've successfully processed your payment to {{ data.payee_name }} for
                  {{ data.currency_to }} {{ data.receive }}.  The money will usually reach the recipient's account {{checkCHAPS()}}<br />
                  Please note: if you set up new payee, the payment processing will take longer than usual would it take up to 2 working days.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer" v-if="Object.keys(data).length > 0">
        <div class="row justify-content-xl-center">
          <div class="col-xl-12 text-center">
            <div class="mb-4">Do you want to do more payment and transfer?</div>
            <a :href="`/payment`" class="btn btn-warning">No</a>
            <a :href="`${this.$route.fullPath}`" class="btn btn-success">Yes</a>
  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentApi from '@/api/payment'
export default {
  props:{
    data: Object
  },
  methods:{
    downloadRecive(){
      PaymentApi.paymentReceipt(this.data.payment_UID)
    },
    checkCHAPS(){

      if((this.data.currency_from.toLowerCase() === 'gbp' && this.data.currency_to.toLowerCase() === 'gbp' && parseFloat(this.data.amount) > 1000000) || this.data.reference === 'testchapsui'){
        return 'within same day'
      }

      if(this.data.currency_from.toLowerCase() === 'eur' && this.data.currency_to.toLowerCase() === 'eur'){
        return '1 working day'
      }

      return 'within 2 hours'
    }
  }
}
</script>

<style>

</style>