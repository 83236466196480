<template>
  <div class="freetrade-list">
    <el-form-item label="">
      <div>
        You have Free Trade {{ freelists.length || "0" }} times 
      </div>
      <div>
        <el-radio v-model="usedFree" @change="onChange" :label="true" border size="medium">Use it</el-radio>
        <el-radio v-model="usedFree" @change="onChange" :label="false" border size="medium">Keep it for next time</el-radio>
      </div>
      <el-button @click="dialogTableVisible=true;" type="text">Do you want more Free Trade ?</el-button>
           
      
    </el-form-item>

    <el-dialog
      :visible.sync="dialogTableVisible"
      append-to-body
      custom-class="freetrade-modal"
    >
    <div class="text-center"><h3 class="text-white">Make more ‘Free of Charge’ earn it easy! </h3>
    <h2 class="text-white">By invite your friend with code '{{ memberCode }}'</h2></div> 
      <el-image
        class="mb-4"
        src="/img/modal/free_of_charge.jpg"
        style="width: 100%;"
      ></el-image>
      
    </el-dialog>
  </div>
</template>

<script>
import PaymentApi from "@/api/payment";
export default {
  computed:{
    memberCode(){
     return this.$store.getters.membercode;
    }
  },
  data() {
    return {
      loading: false,
      dialogTableVisible: false,
      usedFree: false,
      freelists: [],
      freeSelected: "",
    };
  },
  methods: {
    getFreeTrade() {
      this.loading = true;
      PaymentApi.freetradeList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.freelists = data;
          //this.usedFree = this.freelists.length > 0;
          if(this.usedFree){
            this.onChange();
          }
        }
      });
    },
    onChange() {
      let value = this.freelists.length > 0 ? this.freelists[0].freeuid : "";
      if(!this.usedFree){
         value = "";
      }
      this.$emit("onChanged", value);
    },
  },
  mounted() {
    this.getFreeTrade();
  },
};
</script>

<style lang="scss">
// .freetrade-list {

// }
</style>
